import Vue from 'vue'
import VueRouter from 'vue-router'

import index_index from '../views/index/index.vue'
import icpindex from '../views/icp/index.vue'
import app_index from '../views/app/index.vue'
import app_download_error from '../views/app/download_error.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //     path: '/',
  //     name: 'icpindex',
  //     meta: {
  //       title: "大湘速聘",
  //     },
  //     component: icpindex
  //   },


  {
    path: '/',
    name: 'index',
    meta: {
      title: "大象系统-大湘人力资源集团",
    },
    component: index_index
  },
  {
    path: '/app/download',
    name: 'app_index',
    meta: {
      title: "大象APP下载",
    },
    component: app_index
  },
  {
    path: '/app/download_error',
    name: 'app_download_error',
    meta: {
      title: "大象系统APP下载页",
    },
    component: app_download_error
  }

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title

  }
  next()
})

export default router