<template>
  <div class="main">
    <div class="main_inner">
      <div class="logo_bg_main">
        <div class="logo_bg">
          <div class="dxxt_title">大象系统</div>
        </div>
      </div>


      <div class="login_box">
        <div class="login_box_inner login_box_inner_hover" @click="click_login">
          <div class="waibu_tag2">内部</div>
          <div>
            <span class="iconfont iconweb-channel loginicon"></span>
          </div>
          <span class="login_text">电脑端网页登录</span>
        </div>
        <!-- <div class="login_box_inner2 login_box_inner_hover" @click="click_download_app('android')">
          <div class="waibu_tag2">内部</div>
          <div class="appicon_div2">
            <div class="appicon_div">
              <span class="iconfont iconanzhuo loginicon"></span>
            </div>
            <div class="appicon_div">
              <span class="iconfont iconpingguo loginicon"></span>
            </div>
          </div>
          <span class="login_text">手机端APP下载</span>
        </div> -->
        <!-- <div class="waibu_tag2">内部</div>

        <div class="appicon_div2">
          <div class="appicon_div">
            <span class="iconfont iconanzhuo loginicon"></span>
          </div>
          <div class="appicon_div">
            <span class="iconfont iconpingguo loginicon"></span>
          </div>

        </div> -->
        <!-- <span class="login_text">手机端APP下载</span>
      </div> -->
        <div class="login_box">
          <div>
            <div class="login_box_inner5 " @click="click_daili">
              <div class="waibu_tag">外部</div>
              <div>
                <span class="iconfont iconhezuo loginicon"></span>
              </div>
              <span class="login_text">伙伴(代理)登录</span>
            </div>
          </div>
        </div>
      </div>


      <div class="nav_bottom">
        <a href="">Copyright © {{ current_year }} 大湘人力资源集团.</a>
        <a class="bottom_icp" href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备2022000332号-1</a>

        <img src="../../assets/img/sys/gonganwangbei.png" class="gawb" alt="" />
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo" class="bottom_icp2">湘公网安备430190020016</a>
      </div>
    </div>

    <Modal v-model="phone_sms_show" title="下载验证" :footer-hide="true" width="320">
      <div style="padding: 10px">请使用注册了【大象系统】的手机号进行短信验证</div>
      <div clss="download_div2">
        <Input v-model="phone" style="margin-left: 10px; width: 260px" placeholder="手机号" />
      </div>
      <div clss="download_div2" style="margin-top: 10px">
        <Input v-model="code" style="margin-left: 10px; width: 160px" placeholder="验证码" />
        <Button type="success" style="margin-left: 10px; color: #fff; background: #1cd280"
          @click="request_sms">发送验证码</Button>
      </div>

      <div class="request_sms_btn" @click="click_app_btn">确认下载</div>
    </Modal>
    <Modal v-model="qrcode_show" title="扫描二维码下载APP" :footer-hide="true" width="320">
      <div class="qrcode_div">
        <div class="qrcode" ref="qrCodeUrl"></div>
      </div>

      <div style="margin-top: 10px" v-if="status == 'ios'">
        注意：苹果手机先安装【TestFlight】再安装【大象系统】，安装成功后不可以卸载【TestFlight】，否则无法自动更新
      </div>
      <div style="margin-top: 10px" v-if="status == 'android'">
        注意：扫一扫二维码或直接到应用商店搜索【大象系统】安装
      </div>
    </Modal>

    <div v-if="is_pc" class="triangle"></div>
    <div v-if="is_pc" class="triangle2"></div>
    <div v-if="is_pc" class="circlecontainer">
      <div class="circle"></div>
    </div>
  </div>
</template>

<script>
import { request, open_new_page } from "../../utils/http";
import QRCode from "qrcodejs2";
// import * as dd from 'dingtalk-jsapi';

export default {
  data() {
    return {
      current_year: new Date().getFullYear(),
      phone: "",
      code: "",
      sms_id: "",
      status: "",
      phone_sms_show: false,
      qrcode_show: false,
      is_pc: true,
    };
  },
  mounted() {
    this.is_pc = !this.isMobile();
    if (this.is_pc) {
      // 三角形与方块
      this.moveTriangle();
      this.moveTriangle2();
    }





  },

  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },

    click_download_pc() {
      let obj = {
        status: this.status,
      };
      var that = this;
      request("post", process.env.VUE_APP_AUTH_HOST + "/app/download_pc", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            that.$Message.success(res.data.msg);
            var url = res.data.data.download_url;
            open_new_page(url);

          } else {
            that.$Message.error(res.data.msg);
          }
        }
      );
    },

    click_app_btn() {
      if (this.code.length != 6) {
        this.$Message.error("请输入验证码");
        return;
      }
      if (this.phone.length != 11) {
        this.$Message.error("请输入手机号");
        return;
      }

      let obj = {
        phone: this.phone,
        status: this.status,
        code: this.code,
        sms_id: this.sms_id,
      };
      var that = this;
      request("post", process.env.VUE_APP_AUTH_HOST + "/app/download", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            that.$Message.success(res.data.msg);

            if (that.status == "android") {
              // var urlb64 = Base64.encode(res.data.data.download_url);
              // var download_str = encodeURIComponent(urlb64);
              // console.log(urlb64)
              // console.log(download_str)
              // var url = 'https://www.daxiangsys.com/app/download?version_id=' + res.data.data.download_url
              // console.log(url)
              var url = res.data.data.download_url;

              this.$refs.qrCodeUrl.innerHTML = "";
              var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: url, // 需要转换为二维码的内容
                width: 200,
                height: 200,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
              that.qrcode_show = true;
            }

            if (that.status == "ios") {
              var url = res.data.data.download_url;

              this.$refs.qrCodeUrl.innerHTML = "";
              var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: url, // 需要转换为二维码的内容
                width: 200,
                height: 200,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
              that.qrcode_show = true;
            }

            if (that.status == "win" || that.status == "mac") {
              var url = res.data.data.download_url;
              open_new_page(url);
            }
          } else {
            that.$Message.error(res.data.msg);
          }
        }
      );
    },

    request_sms() {
      if (this.phone.length != 11) {
        this.$Message.error("请输入手机号");
        return;
      }

      let obj = {
        phone: this.phone,
      };
      var that = this;
      request("post", process.env.VUE_APP_AUTH_HOST + "/app/download_sms", obj).then(
        (res) => {
          console.log(res);
          if (res.data.code == 0) {
            that.$Message.success(res.data.msg);
            that.sms_id = res.data.data.sms_id;
          } else {
            that.$Message.error(res.data.msg);
          }
        }
      );
    },

    click_download_app(status) {
      this.status = status;

      if (status == "android" || status == "ios") {
        var url = "https://m.malink.cn/s/RJFr6f";
        this.$refs.qrCodeUrl.innerHTML = "";
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url, // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        this.qrcode_show = true;
      } else {
        console.log(status);
        console.log('pc版下载')
        // this.phone_sms_show = true;
        this.click_download_pc()

      }
    },

    isDingTalk() {
      var userAgent = navigator.userAgent;
      return /DingTalk/i.test(userAgent) || /dingtalk/i.test(window.location.host);
    },

    click_daili() {

      var base_url = "https://huoban.daxiangsys.com/";

      try {
        if (this.isDingTalk()) {
          console.log('是dd')
          var url = 'dingtalk://dingtalkclient/page/link?url=' + encodeURIComponent(base_url) + '&pc_slide=false'
          open_new_page(url);
        } else {
          console.log('不是dd')
          open_new_page(base_url);
        }
      } catch (e) {
        console.log('打开失败')
        open_new_page(base_url);

      }

    },

    click_login() {
      var base_url = "https://auth.daxiangsys.com/";

      try {
        if (this.isDingTalk()) {
          console.log('是dd')
          var url = 'dingtalk://dingtalkclient/page/link?url=' + encodeURIComponent(base_url) + '&pc_slide=false'
          open_new_page(url);
        } else {
          console.log('不是dd')
          open_new_page(base_url);
        }
      } catch (e) {
        console.log('打开失败')
        open_new_page(base_url);

      }



    },

    click_xuqiu() {
      var url = "https://support.qq.com/product/426155";
      open_new_page(url);
    },

    click_sys_log() {
      var url = "https://txc.qq.com/products/426155/change-log";
      open_new_page(url);
    },

    moveTriangle() {
      const triangle = document.querySelector(".triangle");
      let angle = 0; // 设置初始角度为 0
      const maxAngle = 360; // 设置最大角度为 360 度
      const step = 0.4; // 每次旋转的步长

      const interval = setInterval(() => {
        // if (angle >= maxAngle) {
        //     clearInterval(interval); // 到达最大角度后停止定时器
        // } else {
        triangle.style.transform = `rotate(${angle}deg)`; // 更新三角形旋转角度
        angle += step; // 更新角度
        // }
      }, 10); // 每
    },

    moveTriangle2() {
      const triangle = document.querySelector(".triangle2");
      let angle = 100; // 设置初始角度为 0
      const maxAngle = 360; // 设置最大角度为 360 度
      const step = 0.1; // 每次旋转的步长

      const interval = setInterval(() => {
        // if (angle >= maxAngle) {
        //     clearInterval(interval); // 到达最大角度后停止定时器
        // } else {
        triangle.style.transform = `rotate(${angle}deg)`; // 更新三角形旋转角度
        angle += step; // 更新角度
        // }
      }, 10); // 每
    },
  },
};
</script>

<style scoped>
.appicon_div2 {
  display: flex;
  align-items: center;
}

.appicon_div {
  width: 40px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circlecontainer {
  position: fixed;
  left: 5%;
  top: 5%;
  width: 300px;
  height: 150px;
  animation: float-animation 2s infinite ease-in-out;
}

@keyframes circle {
  0% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(40px);
  }

  70% {
    transform: translateX(20px);
  }

  85% {
    transform: translateX(40px);
  }

  92% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(0);
  }
}

.circle {
  bottom: 0;
  width: 70px;
  height: 70px;
  background-color: rgb(27, 194, 255);
  border-radius: 50%;
  -webkit-animation: circle 3s linear infinite;
  animation: circle 3s linear infinite;
}

.triangle2 {
  height: 40vw;
  width: 40vw;
  background-color: #6d92ff;
  border-radius: 70px;
  position: fixed;
  bottom: 20%;
  right: -32vw;
  transform: translateX(-25%);
}

.triangle {
  width: 20vw;
  height: 10vw;
  border-radius: 300px 300px 0 0;
  /* 左上角、右上角、右下角、左下角 */
  /* border-left: 70px solid transparent;
    border-right: 70px solid transparent; */
  background: #98e555;
  position: fixed;
  bottom: 1vw;
  left: -5vw;
  transform: translateX(-10%);
}

.update_log_inner {
  background-color: #0d4d9b;
  border-radius: 100px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
}

.update_log {
  cursor: pointer;
  padding-top: 30px;
  color: #a6bbcf;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update_log_inner:hover {
  color: #fff;
}

.qrcode_tishi {
  text-align: center;
  margin: 10px;
  font-weight: 600;
}

.qrcode_div {
  display: flex;
  justify-content: center;
}

.qrcode {
  width: 200px;
}

.request_sms_btn {
  background-color: #4466dd;
  height: 40px;
  line-height: 40px;
  display: flex;
  margin: 10px;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.download_div2 {
  margin-top: 10px;
  padding-left: 60px;
  display: flex;
  justify-content: space-between;
}

.login_text {
  font-size: 14px;
  font-weight: 600;
}

.loginicon {
  font-size: 32px;
}

.iconanzhuo {
  color: #2b9c25;
}

.iconmac {
  color: #000;
}

.iconpingguo {
  color: #000;
}

.iconwin {
  color: #5ca5ff;
}

.iconxuqiu {
  color: #fff200;
}

.login_box_inner_hover {
  position: relative;
}

.login_box_inner_hover:hover {
  box-shadow: 0px 0px 3px #abb7ef;
  /* border:1px solid #ccc */
}

.login_box_inner_hover5:hover {
  box-shadow: 0px 0px 3px #f69031;
  /* border:1px solid #ccc */
}

.waibu_tag2 {
  border-radius: 0 10px 0 10px;
  background-color: rgb(1, 186, 81);
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 2px 10px;
}

.waibu_tag {
  border-radius: 0 10px 0 10px;
  background-color: rgb(216, 28, 28);
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 2px 10px;
}

.login_box_inner4 {
  cursor: pointer;
  padding: 20px 60px;
  margin: 10px;
  background-color: #2a2a2a;
  border-radius: 10px;
  color: #fff;
}

.login_box_inner5 {
  cursor: pointer;
  position: relative;
  padding: 20px 42px;
  margin: 10px;
  background-color: #faa353;
  border-radius: 10px;
  color: #fff;
}

.login_box_inner5:hover {
  box-shadow: 0 0 3px #c76d31;
}

.login_box_inner {
  padding: 20px 40px;
  margin: 10px;
  background-color: #4466dd;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.login_box_inner2 {
  cursor: pointer;
  padding: 20px 35px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px #dae0e9;
  border-radius: 10px;
}

.login_box_inner3 {
  cursor: pointer;
  width: 160px;
  padding: 20px 35px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px #f6f1f1;

  border-radius: 10px;
}

.login_button {
  border-radius: 100px;
  width: 240px;
  height: 60px;
  /* border: 3px solid #4466dd; */
  background-color: #4466dd;
  font-size: 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login_button:hover {
  border: 3px solid #fff;
}

.login_box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.web_login {}

.nav_bottom {
  margin-top: 100px;
  width: 100%;
  bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 100vw;
}

.gawb {
  margin-left: 10px;
}

.nav_bottom a {
  font-size: 12px;
  color: #ccc;
}

.nav_bottom a:hover {
  color: #4466dd;
}

.logo_txt {
  height: 70px;
}

.main_inner {}

.dxxt_title {
  font-family: "DingTalk";
  font-size: 44px;
  color: #2a2a2a;
}

.logo_bg_main {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.logo_bg {
  width: 400px;
  padding: 5px 30px 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e7e7e7;
}

.logo_bg1 {
  height: 130px;
  background-color: #0466dd;
  border-radius: 50%;
  position: relative;
  top: -65px;
}

.main {
  min-height: 100vh;
  /* width: 100vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
</style>
