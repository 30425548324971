<template>
    <div>
        <div style="height:100px"></div>
        <Icon type="md-information-circle" size="50" color="#1253e5" />
        <div style="height:40px"></div>
        <div style="font-size:14px;margin-top:20px;margin:0 10px;">
            😅自动跳转到手机应用商店竟然失败了！
        </div>
        <div style="font-size:14px;margin:10px 10px;">
            请手动打开应用商店(应用市场)，搜索<span style="font-weight: 600;color: #1253e5;">&nbsp;大象系统&nbsp;</span><br>安装即可!
        </div>
    </div>
</template>

<script>
export default {


}
</script>

<style lang="scss" scoped></style>